import {
  EventValue,
  TrackingService,
} from "../../../../shared/services/tracking.service";

export class PartnersInviteTrackingEvents {
  constructor(
    private readonly brandId: number,
    private readonly campaignId: number,
    private readonly tracking: TrackingService,
  ) {}

  private capture(event: string, value?: EventValue): void {
    this.tracking.capture(["prtns_invt", event], {
      brand_id: this.brandId,
      campaign_id: this.campaignId,
      ...value,
    });
  }

  public partnersInvited(total: number): void {
    this.capture("partners_invited", { event_value: total });
  }

  public invitationOpened(partnerId: number, isProspect: boolean): void {
    this.capture("opened", {
      partner_id: partnerId,
      event_value: isProspect ? "is_prospect" : "",
    });
  }

  public partnerSignedUp(partnerId: number): void {
    this.capture("partner_signed_up", { partner_id: partnerId });
  }

  public invitationAcceptedOnLandingPage(partnerId: number): void {
    this.capture("accepted_lp", {
      partner_id: partnerId,
    });
  }

  public invitationRejectedOnLandingPage(partnerId: number): void {
    this.capture("rejected_lp", {
      partner_id: partnerId,
    });
  }
}
