import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ulid } from "ulid";
import { environment } from "../../../environments/environment";
import { Logger } from "../../../harmony/core";
import { AuthenticationService } from "./api/authentication.service";

export interface EventValue {
  partner_id?: number;
  event_value?: number | string;
}

// GTM might fail if ad-blockers are enabled; this swallow errors
const ignoreErrors = () => {};

@Injectable()
export class TrackingService {
  private logger: Logger;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly gtmService: GoogleTagManagerService,
    logger: Logger,
  ) {
    this.logger = logger.withTag("TrackingService");
  }

  public init(): void {
    if (environment.google.tagManagerId) {
      this.gtmService.addGtmToDom().catch(ignoreErrors);
    }
  }

  public capture(event: string[], metadata?: Record<string, unknown>): void {
    const eventName = ["sp", ...event].join("_");

    metadata = this.addKeyPrefix("sp_", {
      user_id: this.authService.userId,
      user_role: this.authService.userRole,
      event_id: ulid(),
      event_epoch_ms: Date.now(),
      event_value: undefined,
      ...metadata,
    });

    if (eventName.length > 40) {
      this.logger.error(`Event name is too long (max 40 chars): ${eventName}`);
    }

    this.gtmService
      .pushTag({
        event: eventName,
        ...metadata,
      })
      .catch(ignoreErrors);
  }

  private addKeyPrefix(
    prefix: string,
    data: Record<string, unknown>,
  ): Record<string, unknown> {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [`${prefix}${key}`, value]),
    );
  }
}
